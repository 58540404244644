
import { Component, Prop, Vue } from "vue-property-decorator";

type Payroll = {
  id: number;
  period: string;
  date_from: string;
  duration_h_m: string;
  tasks: Record<string, any>[];
};

@Component
export default class PayrollComponent extends Vue {
  @Prop(Object) readonly payroll!: Payroll;

  get appLanguage() {
    return this.$store.getters["app/language"];
  }
}
