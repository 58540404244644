
import { Component, Vue } from "vue-property-decorator";
import moment from "moment-timezone";

import Payroll from "../components/PayrollComponent.vue";

@Component({
  components: {
    Payroll
  }
})
export default class PayrollsPage extends Vue {
  periods = [
    {
      id: 0,
      period: `${this.$t("EREPORTER.FILTER.ALL")} ${this.$t(
        "EREPORTER.PAYROLLS.TITLE"
      )}`
    },
    {
      id: 1,
      period: this.$t("EREPORTER.PAYROLLS.FILTER.LAST_3_MONTHS"),
      start: moment()
        .subtract(3, "months")
        .endOf("month")
    },
    {
      id: 2,
      period: this.$t("EREPORTER.PAYROLLS.FILTER.LAST_6_MONTHS"),
      start: moment()
        .subtract(6, "months")
        .endOf("month")
    }
  ];

  get deviceInfo() {
    return this.$store.getters["device/deviceInfo"];
  }

  get periodId() {
    return this.$store.getters["payrolls/period"];
  }

  set periodId(value) {
    this.$store.commit("payrolls/setPeriod", value);
  }

  get payrollsData() {
    return this.$store.getters["payrolls/data"];
  }

  get payrollsDataPeriodFiltered() {
    const period = this.periods[this.periodId];

    const unsorted = this.payrollsData?.filter(payroll => {
      return moment(payroll.start_date).isAfter(period.start ?? "2000-01-01");
    });

    return unsorted
      ?.slice()
      .sort(
        (a, b) => moment(b.start_date).unix() - moment(a.start_date).unix()
      );
  }

  mounted() {
    moment.tz.setDefault();
  }

  created() {
    this.$store.dispatch("payrolls/fetchAll").then(({ data }) => {
      this.$store.commit("payrolls/setData", data.data);
    });
  }
}
